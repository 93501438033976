.founder {
    margin: 1rem;
    padding: 1.5rem;
    width: calc((100% - 10px) / 3);
    background-color: var(--gray5);

    .top-section {
        display: flex;
        align-items: center;

        .founder-img{
            width: 100px;
            height: 100px;
            object-fit: cover;
            padding-right: 1rem;
        }

        .header, .subheader {
            margin: 0 !important;
        }

        .header{
            font-size: 22px;
            font-weight: 500;
        }
    }

    .bottom-section{
        margin-top: 1rem;
    }
}