.heystack_card {
    flex: 1;
    text-align: left;
    aspect-ratio: 1;
}

.heystack_card > .content {
    margin: 30px 40px;
}

.heystack_card h3 {
    margin: 18px 0;
}

.heystack_card h3,
.heystack_card p {
    color: var(--gray5);

}