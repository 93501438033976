.navbar {
    z-index: 10;
    background-color: var(--gray1);

    .nav-link {
        color: var(--black);
        
        &:hover {
            font-weight: 500;
        }

        &:hover,
        &:active,
        &:focus {
            color: var(--black);
        }
    }

    &.no-background {
        width: 100%;
        position: absolute;
        background-color: transparent;

        .nav-link {
            color: var(--white);
        }
    }

    &.sticky {
        width: 100%;
        position: fixed;
        color: var(--black);
        background-color: var(--gray1);
    }
}

@media (min-width: 768px) {
    #basic-navbar-nav {
        justify-content: end;
    }

    .nav-link {
        margin-left: 2.5rem !important;
    }
}

@media (max-width: 992px) {
    .navbar {
        background-color: var(--white);
    }

    .no-background {
        background-color: var(--gray1) !important;
        position: relative !important;

        .nav-link {
            color: var(--black) !important;
        }
    }

    .navbar-expand {
        #basic-navbar-nav {
            justify-content: end;
        }
    }

    .navbar-collapse {
        text-align: center !important;
    }

    .nav-link {
        color: var(--black) !important;
    }
}

nav {
    transition: .45s ease;
}