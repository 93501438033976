/* Services link section container */
.services-link_container {
    text-align: center;
    background: var(--white);
}

.services-link_container > div > p {
    max-width: 600px;
    margin-top: 10px;
    margin-bottom: 30px;
}

.services_cards-container > * {
    max-width: 400px;
}

.services_cards-container > *:nth-child(2) {
    margin: 0 10px;
}

.services_cards-container > img {
    background: var(--gray5);
    flex: 1;
    object-fit: cover;
}

/* Partners section */
.partners-container {
    gap: 10px;
}

.partners-container > img {
    min-width: 280px;
    aspect-ratio: 1;
    background: var(--gray5);
}