.icon {
    width: 40px;
    height: 40px;
    display: inline-block;
    margin: 10px 10px 10px 0;
    background-size: 100% 100%;
    cursor: pointer;
}

#instagram {
    background-image: url('../../assets/images/instagram.png');
}

#facebook {
    background-image: url('../../assets/images/facebook.png');
}

#linkedin {
    background-image: url('../../assets/images/linkedin.png');
}

.social-text {
    color: var(--black);
}