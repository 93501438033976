.journey {
    margin: 0 1rem;
    padding: 1.5rem;
    height: fit-content;
    background-color: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    .top-section {

        .header,
        .subheader {
            margin: 0 !important;
        }

        .header {
            font-size: 40px;
            font-weight: 800;
            color: rgba(21, 30, 63, 0.29);
        }

        .subheader {
            font-size: 22px;
            font-weight: 500;
        }
    }

    .bottom-section {
        margin-top: 1rem;
    }
}