.banner-img{
    height: 240px;
}
.banner-container > h1{
    bottom: 14px;
    left: 5%;
    color: var(--white);
    z-index: 2;
    font-weight: 500;
}
.image-overlay{
    height: 240px;
    background-color:rgba(0,0,0,0.5);
    z-index: 1;
    top: 0;
}