.heystack-button {
    position: relative;
    text-align: center;
    padding: 10px 20px !important;
    border-radius: 3px;
    border: none;
    cursor: pointer;
}


/* Primary / Secondary */
.heystack-button p {
    margin: 0 !important;
}

.heystack_btn {
    padding: 16px 24px;
    border-radius: 3px;
    width: fit-content;
}
.heystack_btn:hover {
    text-decoration: none;
}

.heystack_btn-primary {
    background: var(--primary);
    color: var(--black);
}
.heystack_btn-primary:hover {
    color: var(--black);
}

.heystack_btn-secondary {
    background: var(--secondary1);
    color: var(--white);
}
.heystack_btn-secondary:hover {
    color: var(--white);
}