.component-container {
  width: 300px;
  padding: 10px;
  display: inline-block;
  margin-right: 10px;
  box-sizing: border-box;
  margin-top: 0; /* Add this line to adjust the top margin */
}

.icon {
  margin-bottom: 5px; /* Adjust the margin-bottom value as per your requirement */
}

.heading {
  text-align: left;
  margin-top: 0;
}

.description {
  margin-top: 10px;
}
