.footer {
    width: 100%;
    bottom: -162px;
    position: absolute;
    background-color: var(--gray1);

    .navbar {
        position: relative;
        padding: 0 !important;
        background-color: transparent;
    }

    .nav-link {
        color: var(--black) !important;
    }
    
    .navbar-toggler{
        display: none;
    }
}