.about-us {
    .image_description_background {
        background-color: var(--white);
    }

    .journey-section {
        padding: 3rem;

        .journey-container {
            display: flex;
            position: relative;

            .journey_img {
                right: 0;
                bottom: 0;
                width: 700px;
                padding: 0 1rem;
                position: absolute;
            }

            .journey:nth-child(1) {
                margin-top: 9%;
            }

            .journey:nth-child(2) {
                margin-top: 6%;
            }
        }
    }

    .founder-section {
        padding-top: 3rem !important;

        .founder-section-header {
            text-align: center;
        }

        .founder-card-container {
            display: flex;
            padding: 0 2rem;
            justify-content: space-between;
        }
    }
}