h1 {
  font-size: 80px;
}

.hero-image {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 12%;
  justify-content: start;
}

.hero-text {
  text-align: left;
  color: var(--white);
  min-width: 400px;
  width: 35vw;
}

.hero-text p {
  margin-bottom: 70px; /* Add space below the paragraph text */
  color: var(--white);
}

@media screen and (max-width: 500px) {
  .hero-image {
    justify-content: center;
  }

  .hero-text {
    text-align: center;
  }
}