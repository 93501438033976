.heystack-blogCard {
    cursor: pointer;

    .card {
        height: 100%;
        transition: .3s ease;

        &:hover {
            box-shadow: rgba(17, 12, 46, 0.07) 0px 30px 100px 0px;
            transform: translateY(-5px);
        }
    }

    .card-body {
        text-align: justify;
    }

    .card-subTitle {
        margin: 0;
    }
}