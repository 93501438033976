.contact-container {
    position: relative;
    width: 1000px;
    margin: 6.5vh auto;
    padding-bottom: 2rem;
}

.contact-container > form {
    width: 55%;
    background: white;
    padding: 40px;
    padding-bottom: 58px;
    padding-right: calc(50px + 5%);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.contact-container > form a {
    margin-top: 20px;
}

.contact-container > form .recaptcha-wrapper {
    margin-bottom: 20px;
}

.contact-container > div {
    width: 50%;
    background: #E5E5E5;
    padding: 60px 40px;
    position: absolute;
    right: 0;
    top: 12.5%;
}

.contact-container > div p {
    margin-top: 20px;
    margin-bottom: 50px;
}

.icon {
    border-radius: 10px !important;
}