.image_description {
    position: relative;
    flex-direction: wrap;

    .image_description_background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--gray2);
        z-index: -2;
    }
}

.imgDesc-imgcontainer > img {
    display: block;
    object-fit: cover;
    background: var(--gray5);
}

.image_description>div>p {
    margin-top: 20px;
    margin-bottom: 30px;
}

.imgDesc-imgcontainer {
    position: relative;
}

.image_description>img {
    background: var(--gray5);
    width: 300px;
    height: 380px;
    overflow: visible;
    object-fit: cover;
}

.imgDesc-imgcontainer > img {
    display: block;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.image_description .img-right {
    margin-left: 5.5vw;
}

.image_description .img-left {
    margin-right: 6.5vw;
}

.image_description .image-right {
    content: '';
    position: absolute;
    bottom: -15px;
    right: -15px;
    width: 120px;
    height: 120px;
    background: var(--secondary2);
    z-index: -1;
}

.image_description .image-left {
    content: '';
    position: absolute;
    bottom: -15px;
    left: -15px;
    width: 120px;
    height: 120px;
    background: var(--secondary2);
    z-index: -1;
}

@media screen and (max-width: 500px) {
    #imageDesc-container {
        flex-direction: column-reverse !important;
        gap: 55px;
    }
}