body {
  font-family: 'Poppins', sans-serif;
  color: var(--black);
  background: var(--gray5);
  min-height: 100vh;
  height: fit-content;
  position: relative;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

:root {
  --primary: #E7BE82;
  --secondary1: #151E3F;
  --secondary2: #030027;

  --orange1: #F6DEBB;
  --orange2: #F0D3AA;
  --orange3: #E7BE82;
  
  --gray1: #F5F5F5;
  --gray2: #F2F2F2;
  --gray3: #EBEBEB;
  --gray4: #E5E5E5;
  --gray5: #DCDCDC;
  --gray6: #737373;

  --black: #343434;
  --white: #FAFAFA;
}

body {
  min-width: 100vh;
  position: relative;
  background: var(--white);
}

h1 {
  font-size: 66px;
  font-weight: 600;
  margin-bottom: 20px;
}

h2 {
  font-size: 34px;
  font-weight: 600;
}

h3 {
  font-size: 26px;
  font-weight: 600;
}

p {
  font-size: 18px;
}

a {
  text-decoration: none;
}

.heystack-button {
  position: relative;
  text-align: center;
  padding: 10px 20px !important;
  border-radius: 3px;
  border: none;
  cursor: pointer;
}


/* Form Buttons */
.heystack_formBtn {
  border: none;
  padding: 16px 24px;
  border-radius: 3px;
  width: fit-content;
  background: var(--primary);
  color: var(--black);
}

.heystack_formBtn:hover {
  text-decoration: none;
}