.star{
    width:2%;
    height:2%;
}
.heading{
    font-size: 25px;
    font-weight: bold;
}
.reviewCardContainer{
    border: 2px solid var(--gray3);
    border-radius: 20px;
    margin: 0% 0.5% 0% 0.5%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height:400px;
}
.reviewCardMessage{
    font-size: 18px;
}
.reviewCardDate{
    font-size: 13px;
}