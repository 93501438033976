.advantage-tick-container {
  display: flex;
  align-items: right;
}

.image {
  width: 80%;
  height: 50%;
}

.description-container {
  flex: 2;
  padding-left: 10px;
}