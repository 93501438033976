.not-found{
    text-align: center;
    padding: 150px 10%;
    background-color: white;

    #error404-img{
        margin-bottom: 30px;
    }

    .error404-text {
        margin-bottom: 70px;
    }
}