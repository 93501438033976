.reviews-heading{
    margin-top: 1rem;
    color:var(--gray6);
}
.reviews-img-container{
    background-color: var(--gray5);
    width:100%;
}
.reviews-container{
   display: grid;
   grid-template-columns: 1.5fr 1fr;
   justify-content: space-between;
   grid-column-gap: 200px;
}
.reviews-form input,.reviews-form textarea{
    width: 100%;
}
.reviews-form input{
    padding: 8px;
}
.card-grid-container{
    margin-bottom: 2%;
    padding-bottom: 2rem;
}
.service-field{
    margin-bottom: 5px;
}
.reviewBtn{
    width:25% !important;
}
.reviews-form{
    width: 100%;
    background: white;
    padding: 40px;
    padding-bottom: 58px;
    padding-right: calc(50px + 5%);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.field-input{
    border:0px;
    border-radius: 10px;
}
.error-msg{
    color:red;
}
.name-field{
    margin: 10px 0px 5% 0px;
}
.message-field{
    margin: 5px 0px 20px 0px;
}
.service-field{
    margin-bottom: 15px;
}

/* stars */
.star-rating ~ label svg{
    fill: grey;
}

.star-rating:checked ~ label svg{
    fill: #EFBC68;
}
.star-rating{
    display: none;
}