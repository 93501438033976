.blog-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 12px;
    row-gap: 20px;
    margin: 4vh 0 8vh 0;
}

@media (max-width: 768px) {
    .blog-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 576px) {
    .blog-container {
        grid-template-columns: 1fr;
    }
}

.card-img-top {
    height: 280px;
    object-fit: cover;
}